<template>
  <div>
    <be-form-group
      label-for="invitation-parent-company"
      :label="$t('attributes.company')"
    >
      <input
        :value="localInvitation.parent_company_id"
        type="hidden"
        name="company_group_ownership[parent_company_id]"
        class="hidden"
      />

      <be-form-input
        id="invitation-parent-company"
        :model-value="parentCompanyTitle"
        readonly
      />
    </be-form-group>

    <be-form-group
      v-if="viaOptions.length > 1"
      label-form="invite-via"
      :label="$t('activerecord.attributes.company_group_ownership.via')"
    >
      <be-form-radio-group
        id="invite-via"
        v-model="localInvitation.via"
        name="company_group_ownership[via]"
        :options="viaOptions"
        stacked
        @change="$emit('invitation-via-updated', $event)"
      />
    </be-form-group>

    <input
      v-else
      type="hidden"
      name="company_group_ownership[via]"
      :value="localInvitation.via"
    />

    <be-form-group
      v-if="localInvitation.via === 'email'"
      label-for="recipient-email"
      :label="
        $t('activerecord.attributes.company_group_ownership.recipient_email')
      "
      :description="$t('models.company_group_ownership.hints.recipient_email')"
      :error="getErrors(localInvitation, 'recipient_email')"
    >
      <be-form-input
        id="recipient-email"
        v-model="localInvitation.recipient_email"
        name="company_group_ownership[recipient_email]"
        required
        @change="clearErrors(localInvitation, 'recipient_email')"
      />
    </be-form-group>

    <template v-else>
      <be-form-group
        v-if="companies.length > 0"
        label-for="invitation-child-company"
        :label="
          $t('activerecord.attributes.company_group_ownership.child_company_id')
        "
        :error="getErrors(localInvitation, 'child_company')"
        required
      >
        <input
          :value="selectedChildCompany"
          type="hidden"
          name="company_group_ownership[child_company_id]"
          class="hidden"
        />

        <be-form-select
          id="invitation-child-company"
          v-model="selectedChildCompany"
          :options="companies"
          option-label="title"
          option-value="id"
          @change="clearErrors(localInvitation, 'child_company')"
        >
          <template #option="{ option }">
            <div class="d-flex align-items-center gap-1 flex-wrap">
              {{ option.title }}
              <small v-if="option.organization_number" class="text-muted">
                ({{ option.organization_number }})
              </small>
            </div>
          </template>
        </be-form-select>
      </be-form-group>

      <be-alert v-else variant="info">
        {{
          $t(
            "components.companies.corporate_group.form.no_available_child_companies"
          )
        }}
      </be-alert>
    </template>

    <be-form-group
      v-if="localInvitation.via === 'email'"
      label-for="invitation-message"
      :label="translateAttribute('company_group_ownership', 'message')"
      :error="getErrors(localInvitation, 'message')"
      class="mb-0"
    >
      <be-form-textarea
        id="invitation-message"
        v-model="localInvitation.message"
        name="company_group_ownership[message]"
        :state="validationState(localInvitation, 'message')"
        rows="3"
        @change="clearErrors(localInvitation, 'message')"
      />
    </be-form-group>
  </div>
</template>

<script>
import TextUtilities from "@/mixins/textUtilities";

export default {
  mixins: [TextUtilities],

  props: {
    initialInvitation: {
      type: Object,
      required: true,
    },

    companies: {
      type: Array,
      required: true,
    },

    parentCompanyTitle: {
      type: String,
      required: true,
    },

    inClientManager: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  emits: ["invitation-via-updated"],

  data() {
    const localInvitation = this.cloneDeep(this.initialInvitation);
    const selectedChildCompany = this.companies.find(
      (company) => company.id == this.initialInvitation.child_company_id
    );

    return {
      localInvitation,
      selectedChildCompany: selectedChildCompany?.id || null,
    };
  },

  computed: {
    isFortnox() {
      return this.$platform.theme.theme_name === "fortnox";
    },

    viaOptions() {
      const options = [];

      if (this.inClientManager) {
        options.push({
          text: this.$t("models.company_group_ownership.via.admin_panel"),
          value: "admin_panel",
        });
      } else {
        options.push({
          text: this.$t("models.company_group_ownership.via.membership"),
          value: "membership",
        });
      }

      if (!this.isFortnox) {
        options.push({
          text: this.$t("models.company_group_ownership.via.email"),
          value: "email",
        });
      }
      return options;
    },
  },

  watch: {
    viaOptions: {
      immediate: true,

      handler(viaOptions) {
        const values = viaOptions.map((option) => option.value);
        // Prevent default values not available in the options
        if (!values.includes(this.localInvitation.via)) {
          this.localInvitation.via = viaOptions[0].value;
        }
      },
    },
  },
};
</script>
